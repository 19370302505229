var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uploader" }, [
    _c(
      "div",
      { staticClass: "file-box" },
      [
        _vm._l(_vm.fileBox, function(item) {
          return _c("div", { staticClass: "file-list" }, [
            _vm._v(_vm._s(item.name))
          ])
        }),
        _c("div", { staticClass: "fileinput-button" }, [
          _c("span", [_vm._v("+")]),
          _c("input", {
            ref: "refName",
            attrs: { multiple: "", type: "file" },
            on: {
              change: function($event) {
                return _vm.fileUpload($event)
              }
            }
          })
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }