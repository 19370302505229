var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "container" } },
    [
      _c("goback", { attrs: { msg: _vm.msg, url: "/" } }),
      _c("div", { staticClass: "basics" }, [
        _c("div", { staticClass: "basics-top" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "result" },
            [
              _c("router-link", { attrs: { to: "/caseList" } }, [
                _c("img", {
                  attrs: { src: require("../../assets/images/cx.png"), alt: "" }
                }),
                _vm._v("查询既往结果")
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "basics-bot" },
          [
            _c(
              "van-form",
              [
                _c("div", { staticClass: "wh-box line" }, [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                    _vm._v(" 姓名 "),
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("../../assets/images/wh.png")
                      },
                      on: {
                        click: function($event) {
                          return _vm.wentishou("name")
                        }
                      }
                    }),
                    _vm.layershow == "name"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              "z-index": "999",
                              left: ".4rem"
                            }
                          },
                          [
                            _c("div", { staticClass: "name-box" }, [
                              _c("div", { staticClass: "triangle-up" }),
                              _c("div", { staticClass: "name-center" }, [
                                _vm._v(
                                  "姓名、出生日期与后续投保环节直接关联,且出生日期是判断投保及计算保费的重要依据，请您准确填写"
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    [
                      _c("van-field", {
                        attrs: {
                          name: "姓名",
                          label: "",
                          placeholder: "请输入姓名",
                          "input-align": "right"
                        },
                        on: { blur: _vm.onBlur },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "wh-box line" }, [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                    _vm._v(" 出生日期 ")
                  ]),
                  _c(
                    "div",
                    [
                      _c("van-field", {
                        attrs: {
                          readonly: "",
                          clickable: "",
                          name: "picker",
                          value: _vm.birthday,
                          label: "",
                          "input-align": "right",
                          placeholder: "请选择出生日期"
                        },
                        on: {
                          click: function($event) {
                            _vm.showBirthday = true
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "van-popup",
                  {
                    attrs: { position: "bottom" },
                    model: {
                      value: _vm.showBirthday,
                      callback: function($$v) {
                        _vm.showBirthday = $$v
                      },
                      expression: "showBirthday"
                    }
                  },
                  [
                    _c("van-datetime-picker", {
                      attrs: {
                        type: "date",
                        title: "选择年月日",
                        "min-date": _vm.minDate,
                        "max-date": _vm.maxDate
                      },
                      on: {
                        confirm: _vm.getBirthday,
                        cancel: function($event) {
                          _vm.showBirthday = false
                        }
                      },
                      model: {
                        value: _vm.currentDate,
                        callback: function($$v) {
                          _vm.currentDate = $$v
                        },
                        expression: "currentDate"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "wh-box line" }, [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                    _vm._v(" 有无社保 ")
                  ]),
                  _c(
                    "div",
                    [
                      _c("pickerPopup", {
                        attrs: {
                          columns: _vm.shebaoValue,
                          title: "",
                          val: _vm.ruleForm.isSocialSecurity
                        },
                        on: { childByValue: _vm.getIsSocialSecurity }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "wh-box line",
                    staticStyle: { position: "relative" }
                  },
                  [
                    _c("div", { staticStyle: { width: "65%" } }, [
                      _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                      _vm._v(" 是否新辅助治疗 "),
                      _c("img", {
                        attrs: {
                          alt: "",
                          src: require("../../assets/images/wh.png")
                        },
                        on: {
                          click: function($event) {
                            return _vm.wentishou("fuzhu")
                          }
                        }
                      }),
                      _vm.layershow == "fuzhu"
                        ? _c(
                            "div",
                            {
                              staticClass: "fuzhu-box",
                              staticStyle: {
                                position: "absolute",
                                "z-index": "999",
                                left: "1.9rem"
                              }
                            },
                            [
                              _c("div", { staticClass: "triangle-up" }),
                              _c("div", { staticClass: "name-center" }, [
                                _vm._v(
                                  "乳腺癌新辅助治疗是指手术前做的一系列治疗(如化疗，内分泌药物，靶向药物，免疫药物、放疗)"
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      [
                        _c("pickerPopup", {
                          attrs: {
                            columns: _vm.xuanzeValue,
                            title: "",
                            val: _vm.ruleForm.isCompTherapy
                          },
                          on: { childByValue: _vm.getIsCompTherapy }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c("div", { staticClass: "line" }, [
                  _c("div", { staticClass: "wh-box" }, [
                    _c("div", { staticStyle: { position: "relative" } }, [
                      _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                      _vm._v(" 保额 "),
                      _c("img", {
                        attrs: {
                          alt: "",
                          src: require("../../assets/images/wh.png")
                        },
                        on: {
                          click: function($event) {
                            return _vm.wentishou("baoe")
                          }
                        }
                      }),
                      _vm.layershow == "baoe"
                        ? _c(
                            "div",
                            {
                              staticClass: "baoe-box",
                              staticStyle: {
                                position: "absolute",
                                "z-index": "999",
                                left: ".2rem"
                              }
                            },
                            [
                              _c("div", {
                                staticClass: "triangle-up",
                                staticStyle: { "margin-left": ".5rem" }
                              }),
                              _c("div", { staticClass: "baoe-center" }, [
                                _c("table", [
                                  _c("tr", [
                                    _c("th", [_vm._v("保额(包含下列项)")]),
                                    _c("th", { attrs: { width: "15%" } }, [
                                      _vm._v("10万")
                                    ]),
                                    _c("th", { attrs: { width: "15%" } }, [
                                      _vm._v("40万")
                                    ]),
                                    _c("th", { attrs: { width: "15%" } }, [
                                      _vm._v("150万")
                                    ])
                                  ]),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v("社保范围内的自付费用限额")
                                    ]),
                                    _c("td", [_vm._v("10万")]),
                                    _c("td", [_vm._v("20万")]),
                                    _c("td", [_vm._v("50万")])
                                  ]),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v("社保范围外的自付费用限额")
                                    ]),
                                    _c("td", [_vm._v("/")]),
                                    _c("td", [_vm._v("10万")]),
                                    _c("td", [_vm._v("50万")])
                                  ]),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v("特定恶性肿瘤药品费用限额")
                                    ]),
                                    _c("td", [_vm._v("/")]),
                                    _c("td", [_vm._v("10万")]),
                                    _c("td", [_vm._v("50万")])
                                  ])
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      [
                        _c("pickerPopup", {
                          attrs: {
                            columns: _vm.initList.insuredSum,
                            title: "",
                            val: _vm.ruleForm.insuredSum
                          },
                          on: { childByValue: _vm.getInsuredSum }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.ruleForm.insuredSum == "150"
                    ? _c("div", { staticClass: "bule-zhu" }, [
                        _vm._v(
                          "保额含：社保内限额50万、社保外限额50万、特定恶性肿瘤药品限额50万"
                        )
                      ])
                    : _vm._e(),
                  _vm.ruleForm.insuredSum == "10"
                    ? _c("div", { staticClass: "bule-zhu" }, [
                        _vm._v("保额含：社保内限额10万")
                      ])
                    : _vm._e(),
                  _vm.ruleForm.insuredSum == "40"
                    ? _c("div", { staticClass: "bule-zhu" }, [
                        _vm._v(
                          "保额含：社保内限额20万、社保外限额10万、特定恶性肿瘤药品限额10万"
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "wh-box line" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "70%", "padding-top": ".2rem" } },
                    [
                      _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                      _vm._v(" 社保范围内的自付费用赔付比例 ")
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("pickerPopup", {
                        attrs: {
                          columns: _vm.initList.compensateRatioIn,
                          title: "",
                          zifu: _vm.zifu,
                          val: _vm.ruleForm.inSocialPercentage
                        },
                        on: { childByValue: _vm.getInSocialPercentage }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "fw line" }, [
                  _vm._v("社保范围外的自费费用赔付比例"),
                  _vm.ruleForm.outSocialPercentage != ""
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.outSocialPercentage) + "%")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "wh-box" }, [
                  _c("div", { staticStyle: { width: "70%" } }, [
                    _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                    _vm._v(" 重大疾病保险金1万(可选) "),
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("../../assets/images/wh.png")
                      },
                      on: {
                        click: function($event) {
                          return _vm.wentishou("zhongji")
                        }
                      }
                    })
                  ]),
                  _c(
                    "div",
                    [
                      _c("pickerPopup", {
                        attrs: {
                          columns: _vm.xuanzeValue,
                          title: "",
                          val: _vm.ruleForm.isSeriousIllAmount1W
                        },
                        on: { childByValue: _vm.getIsSeriousIllAmount1W }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "tab" }, [
        _c("div", { staticClass: "tab-title" }, [
          _c(
            "div",
            {
              staticClass: "tab-tips",
              class: { active: _vm.tabShow == "img" },
              on: {
                click: function($event) {
                  _vm.tabShow = "img"
                }
              }
            },
            [_vm._v("病历上传")]
          ),
          _c(
            "div",
            {
              staticClass: "tab-tips",
              class: { active: _vm.tabShow == "info" },
              on: {
                click: function($event) {
                  _vm.tabShow = "info"
                }
              }
            },
            [_vm._v("选填信息")]
          ),
          _vm._m(1)
        ]),
        _c("div", { staticClass: "tab-box" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabShow == "img",
                  expression: "tabShow == 'img'"
                }
              ],
              staticClass: "upload"
            },
            [
              _c("testUpload", {
                attrs: {
                  pdfList: _vm.pdfList,
                  picList: _vm.picList,
                  pdfType: _vm.pdfType
                },
                on: {
                  getFiles: _vm.getFiles,
                  delossUrl: _vm.delossUrl,
                  getFilespdf: _vm.getFilespdf,
                  previewPDF: _vm.previewPDF,
                  delOssPDF: _vm.delOssPDF
                }
              }),
              _c("div", { staticClass: "tips" }, [
                _vm._v("请您尽量提供齐全的资料")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabShow == "info",
                  expression: "tabShow == 'info'"
                }
              ],
              staticClass: "upload-from"
            },
            [
              _c(
                "div",
                { staticClass: "line" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "duo-box",
                      on: {
                        click: function($event) {
                          _vm.showPicker = true
                        }
                      }
                    },
                    [
                      _c("div", {}, [_vm._v("已确诊")]),
                      _vm.quezhen.length != 0
                        ? _c(
                            "div",
                            _vm._l(_vm.quezhen, function(item) {
                              return _c(
                                "span",
                                { staticStyle: { "margin-left": ".15rem" } },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                color: "#cfcfcf"
                              }
                            },
                            [_vm._v("请选择")]
                          )
                    ]
                  ),
                  _c(
                    "van-popup",
                    {
                      attrs: { round: "", position: "bottom" },
                      model: {
                        value: _vm.showPicker,
                        callback: function($$v) {
                          _vm.showPicker = $$v
                        },
                        expression: "showPicker"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dx-top", on: { click: _vm.onQueding } },
                        [_vm._v(" 确定 ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "dx-box" },
                        [
                          _c(
                            "van-checkbox-group",
                            {
                              model: {
                                value: _vm.ruleForm.definiteSicknessTypeList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "definiteSicknessTypeList",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.definiteSicknessTypeList"
                              }
                            },
                            _vm._l(_vm.initList.definiteSicknessType, function(
                              item
                            ) {
                              return _c(
                                "van-checkbox",
                                {
                                  staticClass: "margin-2",
                                  attrs: {
                                    name: item.code,
                                    "icon-size": "18px"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.xiugai1(item.code)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ys line" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "duo-box",
                      on: {
                        click: function($event) {
                          _vm.showPickersh = true
                        }
                      }
                    },
                    [
                      _c("div", {}, [_vm._v("已行手术")]),
                      _vm.shoushu.length != 0
                        ? _c(
                            "div",
                            _vm._l(_vm.shoushu, function(item) {
                              return _c(
                                "span",
                                { staticStyle: { "margin-left": ".15rem" } },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                color: "#cfcfcf"
                              }
                            },
                            [_vm._v("请选择")]
                          )
                    ]
                  ),
                  _c(
                    "van-popup",
                    {
                      attrs: { round: "", position: "bottom" },
                      model: {
                        value: _vm.showPickersh,
                        callback: function($$v) {
                          _vm.showPickersh = $$v
                        },
                        expression: "showPickersh"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dx-top", on: { click: _vm.onShoushu } },
                        [_vm._v(" 确定 ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "dx-box" },
                        [
                          _c(
                            "van-checkbox-group",
                            {
                              model: {
                                value: _vm.ruleForm.completedOperationTypeList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "completedOperationTypeList",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.completedOperationTypeList"
                              }
                            },
                            _vm._l(
                              _vm.initList.completedOperationType,
                              function(item) {
                                return _c(
                                  "van-checkbox",
                                  {
                                    staticClass: "margin-2",
                                    attrs: {
                                      name: item.code,
                                      "icon-size": "18px"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.xiugai(item.code)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "wh-box line" }, [
                _c("div", {}, [_vm._v("切缘")]),
                _c(
                  "div",
                  [
                    _c("pickerPopup", {
                      attrs: {
                        columns: _vm.initList.cutEdgeResults,
                        title: "",
                        val: _vm.ruleForm.cutEdgeResults
                      },
                      on: { childByValue: _vm.getCutEdgeResults }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "wh-box line" }, [
                _c("div", {}, [_vm._v("分期")]),
                _c(
                  "div",
                  [
                    _c("pickerPopup", {
                      attrs: {
                        columns: _vm.initList.pathologyTyping,
                        title: "",
                        fenqi: _vm.fenqi,
                        val: _vm.ruleForm.pathologyTyping
                      },
                      on: { childByValue: _vm.getPathologyTyping }
                    })
                  ],
                  1
                )
              ]),
              _vm.ruleForm.pathologyTyping != "01"
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "wh-box line",
                        staticStyle: { padding: "10px 0" }
                      },
                      [_vm._v("免疫组化")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wh-box",
                        staticStyle: {
                          "padding-left": "15px",
                          "font-size": ".24rem"
                        }
                      },
                      [
                        _c("div", {}, [_vm._v("ER")]),
                        _c(
                          "div",
                          [
                            _c("pickerPopup", {
                              attrs: {
                                columns: _vm.initList.ihcErType,
                                title: "",
                                val: _vm.ruleForm.ihcEr
                              },
                              on: { childByValue: _vm.getIhcEr }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wh-box",
                        staticStyle: {
                          "padding-left": "15px",
                          "font-size": ".24rem"
                        }
                      },
                      [
                        _c("div", {}, [_vm._v("PR")]),
                        _c(
                          "div",
                          [
                            _c("pickerPopup", {
                              attrs: {
                                columns: _vm.initList.ihcPrType,
                                title: "",
                                val: _vm.ruleForm.ihcPr
                              },
                              on: { childByValue: _vm.getIhcPr }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wh-box",
                        staticStyle: {
                          "padding-left": "15px",
                          "font-size": ".24rem"
                        }
                      },
                      [
                        _c("div", {}, [_vm._v("Ki-67")]),
                        _c(
                          "div",
                          [
                            _c("pickerPopup", {
                              attrs: {
                                columns: _vm.initList.ihcKi67Type,
                                title: "",
                                val: _vm.ruleForm.ihcKi67
                              },
                              on: { childByValue: _vm.getIhcKi67 }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wh-box",
                        staticStyle: {
                          "padding-left": "15px",
                          "font-size": ".24rem"
                        }
                      },
                      [
                        _c("div", {}, [_vm._v("Her-2")]),
                        _c(
                          "div",
                          [
                            _c("pickerPopup", {
                              attrs: {
                                columns: _vm.initList.ihcHer2Type,
                                val: _vm.ruleForm.ihcHer2,
                                title: ""
                              },
                              on: { childByValue: _vm.getIhcHer2 }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm.ruleForm.ihcHer2 == "20"
                      ? _c(
                          "div",
                          {
                            staticClass: "wh-box",
                            staticStyle: {
                              "padding-left": "15px",
                              "font-size": ".24rem"
                            }
                          },
                          [
                            _c("div", {}, [_vm._v("Fish检测是否扩增")]),
                            _c(
                              "div",
                              [
                                _c("pickerPopup", {
                                  attrs: {
                                    columns: _vm.xuanzeValue,
                                    title: "",
                                    val: _vm.ruleForm.isIhcFish
                                  },
                                  on: { childByValue: _vm.getIsIhcFish }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.ruleForm.pathologyTyping != "01"
                ? _c("div", { staticClass: "ys wh-box line" }, [
                    _c("div", { staticStyle: { position: "relative" } }, [
                      _vm._v("分型 "),
                      _c("img", {
                        attrs: {
                          alt: "",
                          src: require("../../assets/images/wh.png")
                        },
                        on: {
                          click: function($event) {
                            return _vm.wentishou("fenxing")
                          }
                        }
                      }),
                      _vm.layershow == "fenxing"
                        ? _c(
                            "div",
                            {
                              staticClass: "baoe-box",
                              staticStyle: {
                                position: "absolute",
                                "z-index": "999",
                                left: ".4rem"
                              }
                            },
                            [_vm._m(2)]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      [
                        _c("pickerPopup", {
                          attrs: {
                            columns: _vm.initList.ihcTyping,
                            val: _vm.ruleForm.ihcTyping,
                            title: ""
                          },
                          on: { childByValue: _vm.getIhcTyping }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "wh-box" }, [
                _c("div", {}, [
                  _vm._v("术后至今是否确诊为/怀疑为复发或远处转移")
                ]),
                _c(
                  "div",
                  [
                    _c("pickerPopup", {
                      attrs: {
                        columns: _vm.xuanzeValue,
                        title: "",
                        val: _vm.ruleForm.isMetastasis
                      },
                      on: { childByValue: _vm.getIsMetastasis }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "shuoming" }, [
                _vm._v(
                  " 说明：选填信息中若有以上一项或多项不知如何填写，可选择病历上传途径。 "
                )
              ])
            ]
          )
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabShow == "img",
              expression: "tabShow == 'img'"
            }
          ],
          staticClass: "tips"
        },
        [
          _c("div", { staticClass: "title1" }, [_vm._v("必须提供:")]),
          _vm._m(3),
          _c("div", { staticClass: "title2" }, [_vm._v("建议提供:")]),
          _vm._m(4)
        ]
      ),
      _c("div", { staticStyle: { width: "100%", height: "44px" } }),
      _c("div", { staticClass: "btna" }, [
        _c("div", { on: { click: _vm.onSubmit } }, [_vm._v("保存")]),
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.onSubmit("1")
              }
            }
          },
          [_vm._v("提交")]
        )
      ]),
      _c(
        "van-overlay",
        { attrs: { show: _vm.jsshow }, on: { click: _vm.closeLayer } },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _vm.layershow == "zhongji"
                ? _c("div", { staticClass: "zhongji-box" }, [
                    _c("div", { staticClass: "name-center" }, [
                      _c("p", [
                        _vm._v(
                          "1.等待期后确诊初次发生以下一-种或多种即赔付(具体释义详见条款):①恶性肿瘤重度(不包含乳腺癌及因乳腺癌远处转移至其他部位产生的恶性肿瘤)②较重急性心肌梗死③严重脑中风后遗症 ④重大器官移植术或造血干细胞移植术⑤冠状动脉搭桥术(或称冠状动脉旁路移植术)⑥严重慢性肾衰竭。"
                        )
                      ]),
                      _c("p", [
                        _vm._v("2.同时患有乳腺癌、甲状腺癌,不可投保可选责任。")
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          )
        ]
      ),
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.loginShow },
          on: {
            click: function($event) {
              _vm.loginShow = false
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "login-box" },
                [
                  _c("login", {
                    attrs: { loginAll: _vm.loginAll },
                    on: {
                      getLogin: _vm.getLogin,
                      sessionLogin: _vm.sessionLogin
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { width: "5rem", padding: "20px" },
          attrs: { round: "", "close-on-click-overlay": false },
          model: {
            value: _vm.chongfuShow,
            callback: function($$v) {
              _vm.chongfuShow = $$v
            },
            expression: "chongfuShow"
          }
        },
        [
          _c("div", [
            _c("div", [
              _vm._v(
                " 本次上传存在" +
                  _vm._s(this.chongfu) +
                  "张重复资料，我们将剔除后上传，请知悉"
              )
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": ".2rem",
                  "line-height": "32px"
                }
              },
              [
                _c(
                  "van-button",
                  {
                    staticStyle: { "line-height": "32px" },
                    attrs: { type: "info", size: "small" }
                  },
                  [_vm._v("已知悉 0" + _vm._s(_vm.time) + " S")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("*")]),
      _vm._v("必填信息")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "font-size": ".24rem", "margin-left": ".3rem" } },
      [_c("span", [_vm._v("*")]), _vm._v("至少选择一种")]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name-box" }, [
      _c("div", { staticClass: "triangle-up" }),
      _c("div", { staticClass: "name-center" }, [
        _vm._v("病历中若诊断有写分型，请选择对应分型")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [_vm._v("①出院记录(含 手术时及首次确诊乳腺癌时的出院记录) ;")]),
      _c("p", [
        _vm._v(
          "②病理报告(术后石蜡病理、存在新辅助治疗时的穿刺病理,无法区分请均上传) ;"
        )
      ]),
      _c("p", [_vm._v("③FISH检测报告(Her2为2+时提供) ;")]),
      _c("p", [
        _vm._v(
          "④半年内复查报告(乳腺彩超/钼靶、甲状腺彩超、甲功、肿瘤标志物，出院小结提及的其他异常检查结果)及相关病历。"
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          "⑤检查报告(腹部彩超、腋下淋巴结/颈部淋巴结/锁骨.上淋巴结彩超、胸部CT、血常规、骨扫描、出院小结提及的其他异常检查结果等) ;"
        )
      ]),
      _c("p", [_vm._v("⑥手术记录;⑦入院记录(含 手术时及首次确诊乳腺癌时) ;")]),
      _c("p", [_vm._v("⑧术前穿刺报告;⑨术中冰冻病理报告;⑥病案首页.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }