<template>
  <div class="container" id="container">
    <goback :msg='msg' url="/"></goback>
    <div class="basics">
      <div class="basics-top">
        <div class="title"><span>*</span>必填信息</div>
<!--        <div class="result" @click="loginOut"><img src="../../assets/images/cx.png" alt="">退出</div>-->
        <div class="result"><router-link to='/caseList'><img src="../../assets/images/cx.png" alt="">查询既往结果</router-link></div>
      </div>
      <div class="basics-bot">
          <van-form>
              <div class="wh-box line">
                <div style="position: relative">
                  <span class="font-red">*</span> 姓名
                  <img alt="" @click="wentishou('name')" src="../../assets/images/wh.png">
                    <div v-if="layershow=='name'" style="position: absolute; z-index: 999;left: .4rem">
                      <div class="name-box" >
                        <div class='triangle-up'></div>
                        <div class="name-center">姓名、出生日期与后续投保环节直接关联,且出生日期是判断投保及计算保费的重要依据，请您准确填写</div>
                      </div>
                    </div>
                </div>
                <div>
                  <van-field
                      v-model="name"
                      name="姓名"
                      label=""
                      placeholder="请输入姓名"
                      input-align="right"
                      @blur="onBlur"
                  />
                </div>
              </div>
            <div class="wh-box line">
              <div style="position: relative">
                <span class="font-red">*</span> 出生日期
              </div>
              <div>
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="birthday"
                    label=""
                    input-align="right"
                    placeholder="请选择出生日期"
                    @click="showBirthday = true"
                />
              </div>

            </div>

           <!-- :rules="[{ required: true, message: '请选择出生日期' }]" -->
          <van-popup v-model="showBirthday" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="getBirthday"
              @cancel="showBirthday=false"
            />
          </van-popup>
          <div class="wh-box line">
            <div style="position: relative">
              <span class="font-red">*</span> 有无社保
            </div>
            <div>
              <pickerPopup :columns='shebaoValue' title = '' :val='ruleForm.isSocialSecurity'  @childByValue="getIsSocialSecurity"></pickerPopup>
            </div>
          </div>
          <div class="wh-box line" style="position: relative">
            <div style="width: 65%">
              <span class="font-red">*</span> 是否新辅助治疗
              <img alt="" @click="wentishou('fuzhu')" src="../../assets/images/wh.png">
              <div class="fuzhu-box" v-if="layershow=='fuzhu'" style="position: absolute; z-index: 999;left: 1.9rem">
                <div class='triangle-up'></div>
                <div class="name-center">乳腺癌新辅助治疗是指手术前做的一系列治疗(如化疗，内分泌药物，靶向药物，免疫药物、放疗)</div>
              </div>
            </div>
            <div>
              <pickerPopup :columns='xuanzeValue' title = '' :val='ruleForm.isCompTherapy'  @childByValue="getIsCompTherapy"></pickerPopup>
            </div>
          </div>
          <div class="line">
            <div class="wh-box">
              <div style="position: relative">
                <span class="font-red">*</span> 保额
                <img alt="" @click="wentishou('baoe')" src="../../assets/images/wh.png">
                <div class="baoe-box" v-if="layershow=='baoe'" style="position: absolute; z-index: 999;left: .2rem">
                  <div class='triangle-up' style=" margin-left: .5rem;"></div>
                  <div class="baoe-center">
                    <table>
                      <tr>
                        <th>保额(包含下列项)</th>
                        <th width='15%'>10万</th>
                        <th width='15%'>40万</th>
                        <th width='15%'>150万</th>
                      </tr>
                      <tr>
                        <td>社保范围内的自付费用限额</td>
                        <td>10万</td>
                        <td>20万</td>
                        <td>50万</td>
                      </tr>
                      <tr>
                        <td>社保范围外的自付费用限额</td>
                        <td>/</td>
                        <td>10万</td>
                        <td>50万</td>
                      </tr>
                      <tr>
                        <td>特定恶性肿瘤药品费用限额</td>
                        <td>/</td>
                        <td>10万</td>
                        <td>50万</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <pickerPopup :columns='initList.insuredSum' title = '' :val='ruleForm.insuredSum' @childByValue="getInsuredSum"></pickerPopup>
              </div>
            </div>
            <div class="bule-zhu " v-if="ruleForm.insuredSum == '150'">保额含：社保内限额50万、社保外限额50万、特定恶性肿瘤药品限额50万</div>
            <div class="bule-zhu " v-if="ruleForm.insuredSum == '10'">保额含：社保内限额10万</div>
            <div class="bule-zhu " v-if="ruleForm.insuredSum == '40'">保额含：社保内限额20万、社保外限额10万、特定恶性肿瘤药品限额10万</div>
          </div>
          <div class="wh-box line">
            <div style="width: 70%; padding-top: .2rem">
              <span class="font-red">*</span> 社保范围内的自付费用赔付比例
            </div>
            <div>
              <pickerPopup :columns='initList.compensateRatioIn' title = '' :zifu="zifu" :val='ruleForm.inSocialPercentage' @childByValue="getInSocialPercentage"></pickerPopup>
            </div>
          </div>
           <div class="fw line">社保范围外的自费费用赔付比例<span v-if="ruleForm.outSocialPercentage!=''">{{ruleForm.outSocialPercentage}}%</span></div>
           <div class="wh-box">
             <div style="width: 70%">
               <span class="font-red">*</span> 重大疾病保险金1万(可选)
               <img alt="" @click="wentishou('zhongji')" src="../../assets/images/wh.png">
             </div>
             <div>
               <pickerPopup :columns='xuanzeValue' title = '' :val='ruleForm.isSeriousIllAmount1W'  @childByValue="getIsSeriousIllAmount1W"></pickerPopup>
             </div>
           </div>
          </van-form>
      </div>
    </div>
    <div class="tab">
      <div class="tab-title">
          <div @click="tabShow ='img'" class="tab-tips" :class="{active:tabShow=='img'}">病历上传</div>
          <div  @click="tabShow = 'info'"  class="tab-tips" :class="{active:tabShow=='info'}">选填信息</div>
          <div style="font-size:.24rem; margin-left: .3rem"><span>*</span>至少选择一种</div>
      </div>
      <div class="tab-box">
        <div class="upload" v-show="tabShow == 'img'">
          <testUpload @getFiles="getFiles" :pdfList="pdfList" :picList='picList' :pdfType="pdfType" @delossUrl='delossUrl'  @getFilespdf="getFilespdf" @previewPDF="previewPDF" @delOssPDF="delOssPDF"></testUpload>
          <div class="tips">请您尽量提供齐全的资料</div>
        </div>
        <div class="upload-from" v-show="tabShow == 'info'" >
          <div class="line">
            <div class="duo-box"  @click="showPicker = true">
              <div class="">已确诊</div>
              <div v-if="quezhen.length !=0">
                <span v-for="item in quezhen" style="margin-left: .15rem">{{item}}</span>
              </div>
              <div v-else style="text-align: right;color: #cfcfcf">请选择</div>
            </div>
            <van-popup v-model="showPicker" round position="bottom">
              <div class="dx-top" @click="onQueding">
                确定
              </div>
              <div class="dx-box">
                <van-checkbox-group v-model="ruleForm.definiteSicknessTypeList">
                  <van-checkbox class="margin-2" @click="xiugai1(item.code)" v-for="item in initList.definiteSicknessType" :name="item.code"  icon-size="18px">{{item.name}}</van-checkbox>
                </van-checkbox-group>
              </div>
            </van-popup>
          </div>
          <div class="ys line">
            <div class="duo-box"  @click="showPickersh = true">
              <div class="">已行手术</div>
              <div v-if="shoushu.length !=0">
                <span v-for="item in shoushu" style="margin-left: .15rem">{{item}}</span>
              </div>
              <div v-else style="text-align: right;color: #cfcfcf">请选择</div>
            </div>
            <van-popup v-model="showPickersh" round position="bottom">
              <div class="dx-top" @click="onShoushu">
                确定
<!--                <div @click="showPickersh = false" class="">取消</div>-->
<!--                <div >确定</div>-->
              </div>
              <div class="dx-box">
                <van-checkbox-group v-model="ruleForm.completedOperationTypeList">
                  <van-checkbox class="margin-2" @click="xiugai(item.code)" v-for="item in initList.completedOperationType" :name="item.code"  icon-size="18px">{{item.name}}</van-checkbox>
                </van-checkbox-group>
              </div>
            </van-popup>
<!--            <pickerPopup :columns='initList.completedOperationType' title = '已行手术'  :val='ruleForm.completedOperationType' @childByValue="getCompletedOperationType"></pickerPopup>-->
          </div>
          <div class="wh-box line">
            <div class="">切缘</div>
            <div>
              <pickerPopup :columns='initList.cutEdgeResults' title = '' :val='ruleForm.cutEdgeResults' @childByValue="getCutEdgeResults"></pickerPopup>
            </div>
          </div>
          <div class="wh-box line">
            <div class="">分期</div>
            <div>
              <pickerPopup :columns='initList.pathologyTyping' title = '' :fenqi="fenqi" :val='ruleForm.pathologyTyping'  @childByValue="getPathologyTyping"></pickerPopup>
            </div>
          </div>
          <div v-if="ruleForm.pathologyTyping !='01'">
            <div class="wh-box line" style="padding: 10px 0">免疫组化</div>
            <div class="wh-box " style="padding-left:15px; font-size: .24rem ">
              <div class="">ER</div>
              <div>
                <pickerPopup :columns='initList.ihcErType' title = '' :val='ruleForm.ihcEr'  @childByValue="getIhcEr"></pickerPopup>
              </div>
            </div>
            <div class="wh-box " style="padding-left:15px;font-size: .24rem ">
              <div class="">PR</div>
              <div>
                <pickerPopup :columns='initList.ihcPrType' title = '' :val='ruleForm.ihcPr'  @childByValue="getIhcPr"></pickerPopup>
              </div>
            </div>
            <div class="wh-box " style="padding-left:15px;font-size: .24rem  ">
              <div class="">Ki-67</div>
              <div>
                <pickerPopup :columns='initList.ihcKi67Type' title = '' :val='ruleForm.ihcKi67'  @childByValue="getIhcKi67"></pickerPopup>
              </div>
            </div>
            <div class="wh-box " style="padding-left:15px;font-size: .24rem  ">
              <div class="">Her-2</div>
              <div>
                <pickerPopup :columns='initList.ihcHer2Type' :val='ruleForm.ihcHer2' title = ''  @childByValue="getIhcHer2"></pickerPopup>
              </div>
            </div>
            <div class="wh-box " v-if="ruleForm.ihcHer2== '20' " style="padding-left:15px;font-size: .24rem  ">
              <div class="">Fish检测是否扩增</div>
              <div>
                <pickerPopup :columns='xuanzeValue' title = '' :val='ruleForm.isIhcFish'  @childByValue="getIsIhcFish"></pickerPopup>
              </div>
            </div>
          </div>
          <div class="ys wh-box line" v-if="ruleForm.pathologyTyping !='01'">
            <div style="position: relative">分型
              <img alt="" @click="wentishou('fenxing')" src="../../assets/images/wh.png">
              <div class="baoe-box" v-if="layershow=='fenxing'" style="position: absolute; z-index: 999;left: .4rem">
                <div class="name-box" >
                  <div class='triangle-up'></div>
                  <div class="name-center">病历中若诊断有写分型，请选择对应分型</div>
                </div>
              </div>
            </div>
            <div>
              <pickerPopup :columns='initList.ihcTyping' :val='ruleForm.ihcTyping' title = ''  @childByValue="getIhcTyping"></pickerPopup>
            </div>
          </div>
          <div class="wh-box">
            <div class="">术后至今是否确诊为/怀疑为复发或远处转移</div>
            <div>
              <pickerPopup :columns='xuanzeValue' title = '' :val='ruleForm.isMetastasis'  @childByValue="getIsMetastasis"></pickerPopup>
            </div>
          </div>
          <div class="shuoming">
            说明：选填信息中若有以上一项或多项不知如何填写，可选择病历上传途径。
          </div>
        </div>
      </div>
    </div>
    <div class="tips" v-show="tabShow == 'img'">
      <div class="title1">必须提供:</div>
      <div>
        <p>①出院记录(含 手术时及首次确诊乳腺癌时的出院记录) ;</p>
        <p>②病理报告(术后石蜡病理、存在新辅助治疗时的穿刺病理,无法区分请均上传) ;</p>
        <p>③FISH检测报告(Her2为2+时提供) ;</p>
        <p>④半年内复查报告(乳腺彩超/钼靶、甲状腺彩超、甲功、肿瘤标志物，出院小结提及的其他异常检查结果)及相关病历。</p>
      </div>
      <div class="title2">建议提供:</div>
      <div>
        <p>⑤检查报告(腹部彩超、腋下淋巴结/颈部淋巴结/锁骨.上淋巴结彩超、胸部CT、血常规、骨扫描、出院小结提及的其他异常检查结果等) ;</p>
        <p>⑥手术记录;⑦入院记录(含 手术时及首次确诊乳腺癌时) ;</p>
        <p>⑧术前穿刺报告;⑨术中冰冻病理报告;⑥病案首页.</p>
      </div>
    </div>
    <div style="width:100%; height:44px"></div>
    <div class="btna">
      <div @click="onSubmit">保存</div>
      <div @click="onSubmit('1')">提交</div>
    </div>
      <van-overlay :show="jsshow" @click="closeLayer">
        <div class="wrapper" @click.stop>

              <div class="zhongji-box" v-if="layershow=='zhongji'">
                <div class="name-center">
                  <p>1.等待期后确诊初次发生以下一-种或多种即赔付(具体释义详见条款):①恶性肿瘤重度(不包含乳腺癌及因乳腺癌远处转移至其他部位产生的恶性肿瘤)②较重急性心肌梗死③严重脑中风后遗症
                  ④重大器官移植术或造血干细胞移植术⑤冠状动脉搭桥术(或称冠状动脉旁路移植术)⑥严重慢性肾衰竭。</p>
                   <p>2.同时患有乳腺癌、甲状腺癌,不可投保可选责任。</p>
                </div>
              </div>
          </div>
      </van-overlay>
    <van-overlay :show="loginShow" @click="loginShow = false">
      <div class="wrapper" @click.stop>
        <div class="login-box">
          <login @getLogin="getLogin" :loginAll="loginAll" @sessionLogin="sessionLogin"></login>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="chongfuShow" round :close-on-click-overlay='false' style="width: 5rem; padding: 20px">
      <div>
        <div> 本次上传存在{{this.chongfu}}张重复资料，我们将剔除后上传，请知悉</div>
        <div style="text-align: center; margin-top: .2rem;line-height: 32px"><van-button type="info" size="small" style="line-height: 32px">已知悉 0{{time}} S</van-button></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import $ from '../../util/jquery.js'
import {
  newCase,
  editDetailCase,
  enumList,
  caseSubmit,
  getCase,
  getCaseDetail,
  newCaseedit,
  getSigned,
  ocrpicList,
  ossImgdel,
  checkBirthday,
  pdfUploadOver,
  delOssPath, nameBirthday, pdfFiles
} from '@/api'
import Vue from 'vue'
import goback from '../goBack/index.vue'
import pickerPopup from '../module/pickerPopup.vue'
import testUpload from '../upload/upload.vue'
import login from '../login/login.vue'
import { removeCookie } from '@/util/util'
import uploadFiles from "@/views/upload/uploadFiles";
import {Form ,Field,Button,DatetimePicker,Popup,RadioGroup, Radio,Toast,Overlay,Icon,Dialog} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Overlay);
Vue.use(Icon);
export default {
  name: 'medical-recored',
  data() {
    return {
      msg:"我的预核保",
      name:"",
      birthday:"",
      showBirthday:false,
      showPicker:false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      tabShow:'img',
      initList:[],
      radio:true,
      ruleForm:{
        definiteSicknessTypeList:[],
        completedOperationTypeList:[],
        cutEdgeResults:'',
        pathologyTyping:'',
        ihcEr:"",
        ihcPr:"",
        ihcKi67:"",
        ihcHer2:"",
        ihcTyping:"",
        isIhcFish:null,
        isMetastasis:null,
        insuredSum:'',
        isSocialSecurity:null,
        isCompTherapy:null,
        inSocialPercentage:"",
        outSocialPercentage:"",
        isSeriousIllAmount1W:null
      },
      columns:[],
      amount:10,
      move:false,
      picture:[],
      imgs:{},
      selectfiles:'cai',
      banner:{},
      show:true,
      dataObj:{},
      files:{},
      picList:[],
      uploadQueue:[],
      caseId:"",
      toast:{},
      submitType:'',
      jsshow:false,
      layershow:"",
      xuanzeValue:[{
        name:'是',
        code:true
      },{
        name:'否',
        code:false
      }],
      shebaoValue:[{
        name:'有',
        code:true
      },{
        name:'无',
        code:false
      }],
      loginShow:false,
      fenqi:false,
      quezhen:[],
      showPickersh:false,
      shoushu:[],
      showPopover:false,
      zifu:'',
      uplodtext:false,
      caseShow:true,
      uploadQueuePdf:[],
      fileAll:[],
      pdfType:"0",
      pdfOver:'',
      chongfu:0,
      shibai:0,
      chongfuShow:false,
      time:0,
      timer:null,
      pdfList:[],
      timename:null,
      loginAll:{
        mobile:'',
        code:'',
        radio:''
      }
    }
  },
   components: {
     goback,
     pickerPopup,
     testUpload,
     login,
     uploadFiles
   },
  watch: {
    'ruleForm.pathologyTyping':function (val){
      if(val == '01'){
        this.ruleForm.ihcEr="",
        this.ruleForm.ihcPr="",
        this.ruleForm.ihcKi67="",
        this.ruleForm.ihcHer2="",
        this.ruleForm.isIhcFish = ''
      }
    }
  },
  created() {
    if(this.$store.getters.init){
      this.initList = this.$store.getters.init
    }else {
      this.getInit()
    }
   // this.initList = this.$store.getters.init
    if(this.$route.query.caseId){
      this.caseId = this.$route.query.caseId
      this.getCaseDetails()
      this.getocrpicList()
    }else {
      if(sessionStorage.getItem('loginAll')){
        this.loginShow = true
        this.loginAll = JSON.parse(sessionStorage.getItem('loginAll'))
        if(sessionStorage.getItem('name')){
          this.name = sessionStorage.getItem('name')
        }
        if(sessionStorage.getItem('birthday')){
          this.birthday = sessionStorage.getItem('birthday')
        }
        if(sessionStorage.getItem('ruleForm')){
          this.ruleForm = JSON.parse(sessionStorage.getItem('ruleForm'))
        }
      }else {
        if(sessionStorage.getItem('name') || sessionStorage.getItem('birthday') || sessionStorage.getItem('ruleForm')){
          Dialog.confirm({
            title: '温馨提示',
            message: '当前存在未保存数据，是否加载',
            cancelButtonText:"否",
            confirmButtonText:'是'
          })
              .then(() => {
                if(sessionStorage.getItem('name')){
                  this.name = sessionStorage.getItem('name')
                }
                if(sessionStorage.getItem('birthday')){
                  this.birthday = sessionStorage.getItem('birthday')
                }
                if(sessionStorage.getItem('ruleForm')){
                  this.ruleForm = JSON.parse(sessionStorage.getItem('ruleForm'))
                }
              })
              .catch(() => {
                sessionStorage.removeItem('name')
                sessionStorage.removeItem('birthday')
                sessionStorage.removeItem('ruleForm')
              });
        }
      }


    }

  },
  mounted() {

  },
  methods: {
    onBlur(){
      if(this.name !='' && this.birthday !=''){
        if(this.$store.getters.token){
          this.getNameBirthday()
        }

      }
    },
   checked(j){
     if(j<10)
      j='0'+j;
     return j
   },
    //获取生日
    getBirthday(){
      let date = new Date(this.currentDate)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let query={
        birthday:y+'-'+this.checked(m)+'-'+this.checked(d)
      }
      checkBirthday(query).then((res)=>{
        this.showBirthday= false
        this.birthday = y+'-'+this.checked(m)+'-'+this.checked(d)
        if(this.name !='' && this.birthday !=''){
          if(this.$store.getters.token){
            this.getNameBirthday()
          }
        }
      })
    },
    getNameBirthday(){
      let query={
        birthday:this.birthday,
        name:this.name
      }
      nameBirthday(query).then((res)=>{
        console.log(res)
        if(res && this.caseId != res.id){
          Dialog.confirm({
            title: '温馨提示',
            message: '已存在该用户的案件，请核实是否重复录入',
            cancelButtonText:"返回原案件",
            confirmButtonText:'修改本案件'
          })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            sessionStorage.setItem('name',this.name);
            sessionStorage.setItem('birthday',this.birthday);
            sessionStorage.setItem('ruleForm',JSON.stringify(this.ruleForm));
            if(res.status == '01'){
              this.caseId = res.id
              this.getCaseDetails()
              this.getocrpicList()
            }else if(res.status == '02'){
              this.$router.push({path:'/caseDetails',query:{'caseId':res.id,'type':'01'}})
            }else if(res.status == '04'){
              this.$router.push({path:'/caseDetails',query:{'caseId':res.id,'status':res.status}})
            }else if(res.status == '05'){
              this.$router.push({path:'/caseDetails',query:{'caseId':res.id,'supplement':'01'}})
            }else{
              this.$router.push({path:'/caseDetails',query:{'caseId':res.id}});
            }

          });
        }
      })
    },
    //获取枚举信息
    getInit(){
      enumList().then((res) => {
        let obj = {};
        res.map((e) => {
          obj[e.enumType] = e.enumList;
        });
        this.initList = obj
      })
    },
    getCutEdgeResults(val){
      this.ruleForm.cutEdgeResults = val
    },
    getPathologyTyping(val){
      if(val == '01' && this.ruleForm.isCompTherapy){
        this.fenqi = true
        Toast('新辅助治疗不可选择0期，请核实真实情况，上传病历由人工审核！');
      }else {
        this.ruleForm.pathologyTyping = val
      }

    },
    getIhcEr(val){
      this.ruleForm.ihcEr = val
    },
    getIhcPr(val){
      this.ruleForm.ihcPr = val
    },
    getIhcKi67(val){
      this.ruleForm.ihcKi67 = val
    },
    getIhcHer2(val){
      this.ruleForm.ihcHer2 = val
    },
    getIhcTyping(val){
      this.ruleForm.ihcTyping = val
    },
    getInsuredSum(val){
      this.ruleForm.insuredSum = val
      this.zifu = Date.parse(new Date())
      this.ruleForm.inSocialPercentage = '';
      this.ruleForm.outSocialPercentage = ''
    },
    getInSocialPercentage(val,other){
      this.ruleForm.inSocialPercentage = val;
      if(this.ruleForm.insuredSum !== '10'){
        this.ruleForm.outSocialPercentage = other.code
      }

    },
    getIsSeriousIllAmount1W(val){
      this.ruleForm.isSeriousIllAmount1W = val
    },
    getIsCompTherapy(val){
       if( this.ruleForm.pathologyTyping =='01' && val){
         this.fenqi = true
         Toast('新辅助治疗不可选择0期，请核实真实情况，上传病历由人工审核！');
       }else {
         this.ruleForm.isCompTherapy = val
       }

    },
    getIsSocialSecurity(val){
       this.ruleForm.isSocialSecurity = val
    },
    getIsIhcFish(val){
      this.ruleForm.isIhcFish = val
    },
    getIsMetastasis(val){
      this.ruleForm.isMetastasis = val
    },
    //提交
    onSubmit(type){
      this.submitType = type;
      if(this.name == ''){
         Toast('请填写姓名！');
         return
      }
      if(this.birthday == ''){
         Toast('请选择出生日期！');
         return
      }
      if(this.ruleForm.isSocialSecurity == null){
         Toast('请选择有无社保！');
         return
      }
      if(this.ruleForm.isCompTherapy == null){
         Toast('请选择是否新辅助治疗！');
         return
      }
      if(this.ruleForm.insuredSum == ''){
         Toast('请选择保额！');
         return
      }
      if(this.ruleForm.inSocialPercentage == ''){
         Toast('请选择社保范围内的自付费用赔付比例！');
         return
      }
      if(this.ruleForm.isSeriousIllAmount1W == null){
         Toast('请选择是否投保重大疾病保险金1万！');
         return
      }
      if(type == '1'){
        if(this.uploadQueue.length == 0 && this.picList.length == 0 && this.uploadQueuePdf.length == 0 ){
          if(this.tabShow == '1'){
            Toast('请选择上传病历资料或填写病情信息！');
            return
          }else{
            if(this.ruleForm.definiteSicknessTypeList.length == '0'){
              Toast('请选择确诊信息！');
              return
            }
            if(this.ruleForm.completedOperationTypeList.length == '0'){
              Toast('请选择确是否已行手术！');
              return
            }
            if(this.ruleForm.cutEdgeResults == ''){
              Toast('请选择确切缘！');
              return
            }
            if(this.ruleForm.pathologyTyping == ''){
              Toast('请选择确分期！');
              return
            }
            if(this.ruleForm.pathologyTyping != '01'){
              if( this.ruleForm.ihcEr == '' && this.ruleForm.ihcPr == '' && this.ruleForm.ihcKi67 == '' && this.ruleForm.ihcHer2 == '' && this.ruleForm.ihcTyping == ''){
                Toast('请选择免疫组化或分型！');
                return
              }
              if(this.ruleForm.ihcEr != ''){
                if(this.ruleForm.ihcPr == ''){
                  Toast('请选择PR！');
                  return
                }
                if(this.ruleForm.ihcKi67 == ''){
                  Toast('请选择Ki-67！');
                  return
                }
                if(this.ruleForm.ihcHer2 == ''){
                  Toast('请选择Her-2！');
                  return
                }
              }
              if(this.ruleForm.ihcPr != ''){
                if(this.ruleForm.ihcEr == ''){
                  Toast('请选择ER！');
                  return
                }
                if(this.ruleForm.ihcKi67 == ''){
                  Toast('请选择Ki-67！');
                  return
                }
                if(this.ruleForm.ihcHer2 == ''){
                  Toast('请选择Her-2！');
                  return
                }
              }
              if(this.ruleForm.ihcKi67 != ''){
                if(this.ruleForm.ihcEr == ''){
                  Toast('请选择ER！');
                  return
                }
                if(this.ruleForm.ihcPr == ''){
                  Toast('请选择PR');
                  return
                }
                if(this.ruleForm.ihcHer2 == ''){
                  Toast('请选择Her-2！');
                  return
                }
              }
              if(this.ruleForm.ihcHer2 != ''){
                if(this.ruleForm.ihcEr == ''){
                  Toast('请选择ER！');
                  return
                }
                if(this.ruleForm.ihcPr == ''){
                  Toast('请选择PR！');
                  return
                }
                if(this.ruleForm.ihcKi67 == ''){
                  Toast('请选择Ki-67！');
                  return
                }
              }
              if(this.ruleForm.ihcHer2 == '20' && this.ruleForm.isIhcFish == null){
                Toast('请选择Fish检测是否扩增！');
                return
              }
            }
            if(this.ruleForm.isMetastasis == null){
              Toast('术请选择后至今是否确诊为/怀疑为复发或远处转移！');
              return
            }
          }
        }
      }
      //判断是否有token
      if(!this.$store.getters.token){
        this.loginShow= true
        return;
      }
      this.getSubmit()
    },
    getSubmit(){
      this.toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      if(this.caseId == ''){
        let query ={
          name:this.name,
          birthday:this.birthday,
        }
        nameBirthday(query).then((res)=>{
          this.toast.clear()
          if(res && this.caseId !=res){
            Dialog.confirm({
              title: '温馨提示',
              message: '已存在该用户的案件，请核实是否重复录入',
              cancelButtonText:"返回原案件",
              confirmButtonText:'修改本案件'
            })
                .then(() => {
                })
                .catch(() => {
                  sessionStorage.setItem('name',this.name);
                  sessionStorage.setItem('birthday',this.birthday);
                  sessionStorage.setItem('ruleForm',JSON.stringify(this.ruleForm));
                  this.caseId = res
                  this.getCaseDetails()
                  this.getocrpicList()
                });
          }else {
            newCase(query).then((res) => {
              sessionStorage.removeItem('name')
              sessionStorage.removeItem('birthday')
              sessionStorage.removeItem('ruleForm')
              this.caseId = res
              if(this.uploadQueue.length != 0 || this.uploadQueuePdf.length != 0){
                this.getSignature()
              }else{
                this.infoBtn()
              }
            })
          }
        })
      }else {
        let query ={
          name:this.name,
          birthday:this.birthday,
          id:this.caseId,
        }
        newCaseedit(query).then((res) => {
          sessionStorage.removeItem('name')
          sessionStorage.removeItem('birthday')
          sessionStorage.removeItem('ruleForm')
          if(this.uploadQueue.length != 0 || this.uploadQueuePdf.length != 0){
            this.getSignature()
          }else{
            this.infoBtn()
          }
        })
      }
    },
    //保存基本信息
    infoBtn(){
      this.ruleForm.caseId = this.caseId
      editDetailCase(this.ruleForm).then((res) => {
        if(this.submitType=='1'){
          this.onSubmita()
        }else{
          this.getocrpicList()
          if(this.uploadQueuePdf.length ==0){
            this.toast.clear()
          }
        }
      })
    },
    //获取文件
    getFilespdf(files,type){
      if(type){
        this.uploadQueuePdf = []
      }
      for( let i = files.length - 1; i >= 0; --i ){
        this.uploadQueuePdf.push( files[i] );
      }
    },
    //获取图片
    getFiles(files,type){
      console.log(files)
      if(type){
        this.uploadQueue = []
      }
      for( let i = files.length - 1; i >= 0; --i ){
        this.uploadQueue.push( files[i] );
       }
      console.log(this.uploadQueue)
      if(this.caseId !=''&& files.length!=0){
        this.uplodtext = true
        this.getSignature()
      }
    },
    //获取oss签名
    getSignature(){
      getSigned(this.caseId).then((res) => {
        this.dataObj=res
        if(this.uplodtext){
          this.startUploadQueueImg()
        }else {
          this.fileAll = [...this.uploadQueue,...this.uploadQueuePdf]
          this.startUploadQueue()
        }
      })
      this.timename=setInterval(
              function() {
                this.timegetSignature()
              }.bind(this),
              28000
      )

    },
    timegetSignature(){
      getSigned(this.caseId).then((res) => {
        console.log(res)
        this.dataObj=res
      })
    },
    startUploadQueueImg(){
      this.toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      if( this.uploadQueue.length == 0){
        clearInterval(this.timename)
        this.toast.clear()
        if(this.uplodtext){
          this.uplodtext = false
          this.getocrpicList()
        }
        return
      }
      let uploadItem = this.uploadQueue.pop();
      let progressAdd = ()=>{
        setTimeout(()=>{
          progressAdd();
        }, 1);
      };
      progressAdd();
      this.ossUpload( uploadItem, ( state )=>{
        this.startUploadQueueImg();
      } );
    },
    startUploadQueue(){
          if( this.fileAll.length <= 0){
            if(this.uploadQueuePdf.length !=0){
              clearInterval(this.timename)
              this.getpdfUploadOver()
              return;
            }else {
              clearInterval(this.timename)
              this.uploadQueue = [];
              this.uploadQueuePdf =[];
              this.pdfType = '1'
              if(this.uplodtext){
                this.getocrpicList()
              }else {
                this.infoBtn()
              }
              return;
            }

          }
          let uploadItem = this.fileAll.pop();
          let progressAdd = ()=>{
              setTimeout(()=>{
                  progressAdd();
              }, 1);
          };
          progressAdd();
          this.ossUpload( uploadItem, ( state )=>{
              this.startUploadQueue();
          } );
      },
    //上传图片
    ossUpload(file, callBack) {
      //  var file = files.file
        const filename = file.name;
        let formData = new FormData();
        var vm = this
        //注意formData里append添加的键的大小写
        formData.append('key', this.dataObj.dir+filename); //存储在oss的文件路径
        formData.append('OSSAccessKeyId', this.dataObj.accessId); //accessKeyId
        formData.append('policy', this.dataObj.policy); //policy
        formData.append('Signature', this.dataObj.signature); //签名
        formData.append('callback', this.dataObj.callback); //回调
        formData.append('success_action_status', 200); //成功后返回的操作码
        formData.append('x-oss-forbid-overwrite', true);
        //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append("file", file);
        $.ajax({
          url: this.dataObj.host,
          type: 'POST',
          data: formData,
          // async: false,
          cache: false,
          contentType: false,
          processData: false,
          headers:{
             'x-oss-forbid-overwrite': true
          },
          success: function (data) {
            if(data.code === '2000'){
              callBack( true );
            }else if(data.code === '9007'){
              vm.chongfu++
              callBack( false);
            }else {
              vm.shibai++
              alert(filename+'上传失败请稍后再试')
              vm.uploadDel(filename)
              callBack( false,);
            }
          },
          error: function (data) {
            if(data.status == 409){
              vm.chongfu++
              callBack( false );
            }else {
              vm.shibai++
              alert(filename+'上传失败请稍后再试')
              vm.uploadDel(filename)
              callBack( false,);
            }
          }
        });
      },
    uploadDel(filename){
      delOssPath(this.caseId,filename).then((res) => {
      })
    },
    //查看pdf是否提交成功
    getpdfUploadOver(){
      this.pdfOver = setInterval(this.getpfdover ,
          500
      )
    },
    getpfdover(){
      pdfUploadOver(this.caseId).then((res) => {
        if(res){
          this.toast.clear()
          if(this.chongfu == 0){
            Toast('保存成功');
          }
          this.uploadQueuePdf =[];
          this.pdfType = '1';
          this.uploadQueue = [];
          if(this.uplodtext){
            this.getocrpicList()
          }else {
            this.infoBtn()
          }
          clearInterval(this.pdfOver)

        }
      })
    },
    //提交
    onSubmita(){
      caseSubmit(this.caseId).then((res) => {
        this.toast.clear()
        Toast('提交成功');
      //  this.$router.push({path:'/caseList'});
        this.$router.push({path:'/'});
      }) 
    },
    //获取缓存数据
    getCaseDetails(){
      getCase(this.caseId).then((res) => {
         this.name = res.name,
        this.birthday = res.birthday
      })
      getCaseDetail(this.caseId).then((res) => {
        this.ruleForm = res;
        if(res.definiteSicknessTypeList){
          let box =[]
          let date = this.initList.definiteSicknessType.filter(item => res.definiteSicknessTypeList.indexOf(item.code) > -1)
          for(let i=0; i < date.length; i++){
            box.push(date[i].name)
          }
          this.quezhen = new Set(box)
        }
        if(res.completedOperationTypeList){
          let box =[]
          let date = this.initList.completedOperationType.filter(item => this.ruleForm.completedOperationTypeList.indexOf(item.code) > -1)
          for(let i=0; i < date.length; i++){
            box.push(date[i].name)
          }
          this.shoushu = new Set(box)
        }
      })
    },
    //获取已上传的图片
    getocrpicList(){
      if(this.chongfu != 0){
        this.chongfuShow = true
        const TIME_COUNT = 5;
        this.time = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.time > 0 && this.time <= TIME_COUNT) {
            this.time--;
          } else {
            this.chongfuShow = false;
            this.chongfu = 0
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
      ocrpicList(this.caseId).then((res) => {
       // this.picList = res
        this.pdfList = res.filter( (res) => res.type === "PDF" );
        this.picList = res.filter( (res) => res.type !== "PDF" );
      })
    },
    //删除图片
    delossUrl(item){
      Dialog.confirm({
        title: '温馨提示',
        message: '此操作将删除本张图片请确认！',
      })
          .then(() => {
            ossImgdel(this.caseId,item.id).then((res) => {
              this.getocrpicList()
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    //解释
    wentishou(type){
        this.layershow = type;
        this.jsshow = true
    },
    //登陆成功
    getLogin(){
       this.loginShow= false
      sessionStorage.removeItem('name')
      sessionStorage.removeItem('birthday')
      sessionStorage.removeItem('ruleForm')
      sessionStorage.removeItem('loginAll')
      this.onSubmit(this.submitType)
    },
    //登出
    loginOut(){
      this.$store.commit('SET_TOKEN','')
      this.$store.commit('SET_SESSION','')
      removeCookie('session')
      removeCookie('token')
    },
    onQueding(){
      if(this.ruleForm.definiteSicknessTypeList.length !=0){
        let box =[]
        let date = this.initList.definiteSicknessType.filter(item => this.ruleForm.definiteSicknessTypeList.indexOf(item.code) > -1)
        for(let i=0; i < date.length; i++){
          box.push(date[i].name)
        }
        this.quezhen = new Set(box)
      }
      this.showPicker = false
    },
    onShoushu(){
      if(this.ruleForm.completedOperationTypeList.length !=0){
        let box =[]
        let date = this.initList.completedOperationType.filter(item => this.ruleForm.completedOperationTypeList.indexOf(item.code) > -1)
        for(let i=0; i < date.length; i++){
          box.push(date[i].name)
        }
        this.shoushu = new Set(box)
      }
      this.showPickersh = false
    },
    closeLayer(){
      this.layershow = ''
      this.jsshow = false
    },
    xiugai(val){
      if(val == '99'){
        this.ruleForm.completedOperationTypeList = ['99']
      }else {
        this.ruleForm.completedOperationTypeList = this.ruleForm.completedOperationTypeList.filter((num,index)=>{return num !== '99'})
      }
    },
    xiugai1(val){
      if(val == '99'){
        this.ruleForm.definiteSicknessTypeList = ['99']
      }else {
        this.ruleForm.definiteSicknessTypeList = this.ruleForm.definiteSicknessTypeList.filter((num,index)=>{return num !== '99'})
      }
    },
    //预览pdf
    previewPDF(item){
      this.$router.push({path:'/previewPDF',query:{id:item.id}});
    },
    //删除pdf
    delOssPDF(item){
      Dialog.confirm({
        title: '温馨提示',
        message: '此操作将删除本PDF文件请确认！',
      })
          .then(() => {
            ossImgdel(this.caseId,item.id).then((res) => {
              this.getocrpicList()
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    //获取登录信息
    sessionLogin(val){
      console.log(val)
      this.loginAll = val
    }
  },
  beforeRouteLeave(to,from,next){
    if(to.name == 'userAgreement' || to.name == 'privacyAgreement'){
      sessionStorage.setItem('loginAll',JSON.stringify(this.loginAll));
      sessionStorage.setItem('name',this.name);
      sessionStorage.setItem('birthday',this.birthday);
      sessionStorage.setItem('ruleForm',JSON.stringify(this.ruleForm));
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y: scroll;
  .basics{
    margin: .3rem;
    .basics-top{
      display: flex;
       justify-content: space-between;
       margin-bottom: .26rem;
       .title{
         font-weight: bold;
         font-size: .28rem;
         span{
          color: #FF0000;
         }
       }
       .result{
         font-size: .28rem;
         a{
            color: #0068FF;
         }
        img{
          width: .24rem;
          height: .24rem;
          float: left;
          margin-right: .1rem;
          margin-top: 0.05rem;
          }
       }
    }
    .basics-bot{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .3rem;
    }
  }
  .tab{
    margin: .3rem;
    .tab-title{
      display: flex;
      line-height: .64rem;
      font-size: .26rem;
      .tab-tips{
        width: 69px;
        background: #FFFFFF;
         box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
        text-align: center;
         border-radius: 4px 4px 0 0 ;
      }
      span{
         color: #FF0000;
      }
      .active{
        background: #0068FF;
        color: #fff;
        border-radius: 4px 4px 0 0 ;
      }
    }
    .tab-box{
       background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      .upload{
        .tips{
          text-align: center;
          color:#0068FF
        }
      }
      .upload-from{
        padding:.3rem;
      }
    }
  }

  .tips{
    padding: .3rem;
    font-size: .26rem;
    line-height: .5rem;
    .title1{
      color: #FE0000;
      font-size: .28rem;
    }
    .title2{
      color: #0068FF;
      margin-top: .4rem;
        font-size: .28rem;
    }
  }
  .btna{
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    //height: 100px;
    div{
      width: 50%;
      line-height: .88rem;
      text-align: center;
      color: #0068FF;
      font-weight: bold;
      background: #fff;
    }
    :last-child{
      background: #0068FF;
      color: #fff;
    }
  }
}
.bule-zhu{
  font-size: .24rem;
  color: rgba(0, 104, 255, .5);
  padding: 0 .16rem;
}
.fw{
  color: #646566;
  padding: 10px 16px 10px 0;
  font-size: .26rem;
  line-height: 24px;
  span{
    float:right;
  }
}
.wh-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646566;
  font-size: .26rem;
 // padding-left: 10px;
 // position: relative;
  img{
   // position: absolute;
    width: .24rem;
    height: .24rem;
    //z-index: 1;
    //top:.26rem;
  }
}

  .name-center{
        width: 4.04rem;
        background: #FFFFFF;
        border-radius: 4px;
        font-size: .26rem;
        padding: .24rem;
    }
    .triangle-up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 10px solid #fff;
      margin-left: .3rem;
    }
    #triangle-down{
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid #fff;
      margin-left: 1.8rem;
    }

  .baoe-center{
     width: 6.04rem;
     background: #FFFFFF;
     border-radius: 4px;
     font-size: .26rem;
     table{
       width: 100%;
       th{
         background: rgba(241, 248, 255, 1);
          line-height: .7rem;
       }
       td{
         text-align: center;
         line-height: .7rem;
         border: 1px solid rgba(146, 157, 163, .1);
       }
     }
  }
  .zhongji-box{
    position: absolute;
    right: 1.5rem;
    top:4rem;
  }
.shuoming{
  font-size: .24rem;
  margin-top: .2rem;
}
.login-box{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 8px 8px 0 0;
}
.line{
  border-bottom:1px solid rgba(146, 157, 163, 0.04);
}
.dx-top{
  width: 100%;
  line-height: .6rem;
  padding: .2rem .3rem;
  text-align: right;
}
.dx-box{
  margin-left: .5rem;

}
.margin-2{
  margin: .4rem 0;
}
.duo-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .26rem;
  color: #646566;
  padding: 10px 16px 10px 0px;
  div:first-child{
    width: 40%;
    margin-right: .3rem;
    text-align: left;
  }
  div:last-child{
    width: 60%;
    text-align: right;
  }
}
.font-red{
  color: #FF0000;
}
</style>
<style>
#container .van-field__label{
  width: 63%;
}
.ys  .van-field__label{
  width: 23% !important;
}
#container .van-field__error-message{
  text-align: right;
}
#container .van-cell::after{
 border: none;
}
#container .van-field__control{
  font-size: .26rem!important;
}
</style>