<template>
  <div class="uploader">
    <div class="file-box">
      <div class="file-list" v-for="item in fileBox">{{item.name}}</div>
      <div class="fileinput-button">
        <span>+</span>
        <input @change="fileUpload($event)" multiple  type="file" ref="refName"/>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from 'vue'
import { ImagePreview} from 'vant';
Vue.use(ImagePreview);
export default {
  name: 'uploader',
  data: function () {
    return {
      fileBox:[],
    }
  },
  props: {

  },
  watch:{

  },
  methods: {
    fileUpload(e) {
       var file = e.target.files || e.dataTransfer.files;
       console.log(file)
        this.fileBox = file
       this.$emit('getFilespdf',file)

    },
    //删除图片
    removeUrl(index){
      this.fileBox.splice(index, 1);
      this.$emit('getFilespdf',this.fileBox)
    },

  },
  created(){

  },
  mounted () {

  }
}

</script>
<style lang="scss" >
 .fileinput-button {
  position: relative;
  display: inline-block;
   width: 1.26rem;
   height: 1.48rem;
  border: 1px dashed #C4C4C4;
  text-align: center;
   line-height: 1.48rem;
   margin: .1rem .2rem;
   border-radius: 4px;

   span{
     font-size: .36rem;
     font-weight: bold;
     color: #C4C4C4;
   }
}

        .fileinput-button input{
            position: absolute;
            right: 0px;
            top: 0px;
          width: 1.06rem;
          height: 1.28rem;
            opacity: 0;
        }

</style>