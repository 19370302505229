<template>
  <div class="login" id='login'>
    <div class="title">请先登录</div>
    <div class="from-box">
      <van-form>
        <div class="from-title">手机号码</div>
        <van-field
            v-model="mobile"
            οninput="if(value.length>11)value=value.slice(0,11)"
            type="number"
            maxlength="11"
            clearable
            placeholder="请输入手机号"
            @blur="sessionLogin"
        />
        <div class="from-title magrin-top-30">验证码</div>
        <van-field
            v-model="code"
            center
            clearable
            placeholder="请输入短信验证码"
            @blur="sessionLogin"
          >
        <template #button>
          <van-button size="small" v-show="show" type="default" @click="getCode">获取验证码</van-button>
          <van-button size="small" v-show="!show" type="default">{{count}}秒后重新获取</van-button>
        </template>
      </van-field>
        <van-checkbox v-model="radio"  @change="sessionLogin" icon-size="14px" class="radio-btn">已阅读并同意<span><router-link to='/userAgreement'>用户协议</router-link></span>和<span><router-link to='/privacyAgreement'>隐私政策</router-link></span></van-checkbox>
      <van-button type="info" class="magrin-top-30" round  size="large"  @click="onSubmit">登录</van-button>
      </van-form>
    </div>
    <van-overlay v-if="loadingShow" >
       <van-loading color="#1989fa" />
   </van-overlay>
   
  </div>
</template>

<script>
 import { getUserSendcode,login,sessionBind } from '@/api'
import Vue from 'vue'
import { Form ,Field,Button,DatetimePicker,Popup,RadioGroup, Radio,Toast,Loading} from 'vant';
Vue.use(Form);
Vue.use(Toast);
Vue.use(Loading)
export default {
  name: 'login',
  data() {
    return {
      mobile:'',
      code:'',
      loadingShow:false,
      show:true,
      count:"",
      timer: null,
      radio:""
    }
  },
  props: {
    loginAll: {
      type: Object,
      default: {},
    },
  },
   components: {

   },
  created() {
    this.mobile = this.loginAll.mobile
    this.code = this.loginAll.code
    this.radio = this.loginAll.radio
  },
  methods: {
    sessionLogin(){
      let loginAll ={
        mobile:this.mobile,
        code:this.code,
        radio:this.radio
      }
      this.$emit('sessionLogin',loginAll)
    },
    getCode() {
      if(this.mobile == ''){
        Toast('请输入手机号');
      }else{
        this.loadingShow = true
        getUserSendcode(this.mobile).then((res) => {
          this.loadingShow = false
           Toast('验证码正在发送，请稍后');
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        })
      }
      
    },
    onSubmit(){
      if(this.mobile == ''){
        Toast('请输入手机号');
        return
      }
      if(this.code ==''){
        Toast('请输入短信验证码');
        return
      }
      if(this.radio == ''){
        Toast('请阅读并同意用户协议和隐私政策');
        return
      }
      let query={
       mobile: this.mobile,
       code:this.code
      }
      login(query).then((res) => {
          this.$store.commit('SET_TOKEN', res)
          this.loadingShow = false
          if(this.$store.getters.session){
            let box ={
              session_id:this.$store.getters.session
            } 
            sessionBind(box).then((res) => {
              console.log(res)
              this.$store.commit('SET_SESSION', res)
            })
          }
          Toast('登录成功');
          this.$emit('getLogin')
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  padding-bottom: .4rem;
  .title{
    font-size: 17px;
    font-weight: 500;
    padding: 30px;
  }
  .from-box{
    margin: 0 30px;
  }
  .from-title{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .magrin-top-30{
    margin-top: 30px;
  }
  
}
.radio-btn{
  font-size: .24rem;
  margin-top: .2rem;
  span{
    color: #2970FF;
  }
}
</style>
<style>
#login .van-cell{
  padding: 0 0 10px 0;
}
#login .van-cell:after{
  left: 0!important;
}
</style>