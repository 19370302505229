var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login", attrs: { id: "login" } },
    [
      _c("div", { staticClass: "title" }, [_vm._v("请先登录")]),
      _c(
        "div",
        { staticClass: "from-box" },
        [
          _c(
            "van-form",
            [
              _c("div", { staticClass: "from-title" }, [_vm._v("手机号码")]),
              _c("van-field", {
                attrs: {
                  οninput: "if(value.length>11)value=value.slice(0,11)",
                  type: "number",
                  maxlength: "11",
                  clearable: "",
                  placeholder: "请输入手机号"
                },
                on: { blur: _vm.sessionLogin },
                model: {
                  value: _vm.mobile,
                  callback: function($$v) {
                    _vm.mobile = $$v
                  },
                  expression: "mobile"
                }
              }),
              _c("div", { staticClass: "from-title magrin-top-30" }, [
                _vm._v("验证码")
              ]),
              _c("van-field", {
                attrs: {
                  center: "",
                  clearable: "",
                  placeholder: "请输入短信验证码"
                },
                on: { blur: _vm.sessionLogin },
                scopedSlots: _vm._u([
                  {
                    key: "button",
                    fn: function() {
                      return [
                        _c(
                          "van-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.show,
                                expression: "show"
                              }
                            ],
                            attrs: { size: "small", type: "default" },
                            on: { click: _vm.getCode }
                          },
                          [_vm._v("获取验证码")]
                        ),
                        _c(
                          "van-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.show,
                                expression: "!show"
                              }
                            ],
                            attrs: { size: "small", type: "default" }
                          },
                          [_vm._v(_vm._s(_vm.count) + "秒后重新获取")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.code,
                  callback: function($$v) {
                    _vm.code = $$v
                  },
                  expression: "code"
                }
              }),
              _c(
                "van-checkbox",
                {
                  staticClass: "radio-btn",
                  attrs: { "icon-size": "14px" },
                  on: { change: _vm.sessionLogin },
                  model: {
                    value: _vm.radio,
                    callback: function($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio"
                  }
                },
                [
                  _vm._v("已阅读并同意"),
                  _c(
                    "span",
                    [
                      _c("router-link", { attrs: { to: "/userAgreement" } }, [
                        _vm._v("用户协议")
                      ])
                    ],
                    1
                  ),
                  _vm._v("和"),
                  _c(
                    "span",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/privacyAgreement" } },
                        [_vm._v("隐私政策")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "van-button",
                {
                  staticClass: "magrin-top-30",
                  attrs: { type: "info", round: "", size: "large" },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("登录")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.loadingShow
        ? _c(
            "van-overlay",
            [_c("van-loading", { attrs: { color: "#1989fa" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }